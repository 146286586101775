import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import ReactPlayer from "react-player";
import INFT from "./interfaces/INFT";
import "./App.css";

// web3
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import {
  UserRejectedRequestError,
  InjectedConnector,
} from "@web3-react/injected-connector";
import { ethers } from "ethers";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

// ABIs
import abi_mint from "./abis/GiftDispenser.json";
import abi_nft from "./abis/CallistoNFT.json";

// assets
import nftBox_img from "./assets/nft_box.png";
import logo_img from "./assets/logo.png";
import loadingGif from "./assets/loading.gif";

// vars from env file
const chainIdEnv = Number(process.env.REACT_APP_CHAIN_ID);
const chainUrlEnv = process.env.REACT_APP_CHAIN_URL;
const mintContractAddressEnv = process.env.REACT_APP_MINT_CONTRACT_ADDRESS;
const nftContractAddressEnv = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
// web3
const { ethereum }: any = window;
const metamaskWeb3 = new Web3(ethereum);
const web3 = new Web3(process.env.REACT_APP_CHAIN_URL);

const injected = new InjectedConnector({
  supportedChainIds: [chainIdEnv],
});
const web3Mint = new web3.eth.Contract(
  abi_mint as AbiItem[],
  mintContractAddressEnv
);
const web3MintMeta = new metamaskWeb3.eth.Contract(
  abi_mint as AbiItem[],
  mintContractAddressEnv
);
const nftContract = new web3.eth.Contract(
  abi_nft as AbiItem[],
  nftContractAddressEnv
);

function App() {
  // states
  const [walletConnected, setWalletConnected] = useState(false);
  const [nftExists, setNftExists] = useState(false);
  // Buttons states
  const [btnTxn, setBtnTxn] = useState(false);
  // Console log
  const [consoleLog, setConsoleLog] = useState(
    "Please make sure your Metamask is connected to Callisto Network."
  );
  // NFTs
  const provider = new ethers.providers.JsonRpcProvider(chainUrlEnv);
  // collect all INFT is an array
  const [nfts, setNfts] = useState<INFT[]>([]);
  const contract = new ethers.Contract(
    nftContractAddressEnv,
    abi_nft,
    provider
  );

  const {
    chainId,
    account,
    activate,
    deactivate,
    setError,
    active,
    library,
    connector,
  } = useWeb3React<Web3Provider>();

  const onClickConnect = () => {
    activate(
      injected,
      (error) => {
        if (error instanceof UserRejectedRequestError) {
          // ignore user rejected error
          console.log("user refused");
        } else {
          setError(error);
        }
      },
      false
    );
    setConsoleLog("");
  };

  const onClickDisconnect = () => {
    deactivate();
  };

  // Mint NFT
  const onClickMint = async () => {
    setBtnTxn(true);
    setConsoleLog("Please wait while we mint your NFT...");
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];

      const tx = await web3MintMeta.methods.claim().send({ from: account });
      console.log("tx: ", tx);
      setConsoleLog(
        "Your NFT has been minted! Please check your Metamask wallet."
      );
      // fetchData();
      setNftExists(true);
      document.querySelector('.boxDescription').innerHTML = "You've successfully minted your NFT!";
      document.querySelector('.boxDescription').className = 'successfull';
    } catch (error) {
      console.log("error: ", error);
      setConsoleLog("Something went wrong. Please try again.");
    }
    setBtnTxn(false); // here!!! set to false in original file 
  };

  // Fetch NFTs
  const fetchData = async () => {
    setBtnTxn(true);
    setConsoleLog("Please wait while we fetch your NFTs...");
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];
    const userNFTs: INFT[] = [];

    console.log("starting the search for NFTs for: ", account);
    // Filter all transfers made by this wallet
    const eventTransfer = await contract.filters.Transfer(null, account);
    console.log("eventTransfer: ", eventTransfer);
    const eventTokens = await contract.queryFilter(eventTransfer);
    console.log("eventTokens: ", eventTokens);

    // find all tokens IDs
    const promises = eventTokens.map(async (e) => {
      const tokenId = e.args.tokenId.toNumber();
      console.log("tokenID: ", tokenId);
      const ownerAddress = await contract.ownerOf(tokenId);
      // convert ownerAddress to string uppercase
      const ownerAddressString = ownerAddress.toString().toUpperCase();
      // convert account to string uppercase
      const accountString = account.toString().toUpperCase();
      // Filter user tokens
      if (ownerAddressString === accountString) {
        console.log("Yes for if === ");
        const tokenPropertiesRaw = await contract.getClassPropertiesForTokenID(
          tokenId
        );
        const tokenProperties = JSON.parse(
          tokenPropertiesRaw.toString().replaceAll("},{", ",")
        );
        const nft: INFT = tokenProperties;
        nft.token_id = tokenId;
        // push nft to nfts array
        userNFTs.push(nft);
        console.log("nft: ", nft);
      }
      return true;
    });

    await Promise.all(promises);
    setNfts(userNFTs);
    setBtnTxn(false); // here set to false in original file!!!
    setConsoleLog("");
  };

  // Check wallet balance from ERC-721 contract
  const checkBalance = async () => {
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    const balance = await nftContract.methods.balanceOf(account).call();
    console.log("balance: ", balance);
    if (balance > 0) {
      setNftExists(true);
      // fetchData();
    }
  };

  useEffect(() => {
    if (account) {
      setWalletConnected(true);
      // checkBalance();
    }
  }, [account, active, library, connector]);

  return (
    <div className="App">
      <div className="header">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="https://callisto.network/">
              <img src={logo_img} alt="logo" height="50px" width="200px" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link href="https://t.me/CallistoNet">Join the Callistonians</Nav.Link>
                <Nav.Link href="https://callisto.network/callistonians-nfts/">About</Nav.Link>
                {/* 
                <NavDropdown
                  title="Get Callisto Network Coin"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="https://trading.bitfinex.com/t/CLO:USD?type=exchange">
                    Bitfinex
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://www.gate.io/fr/trade/CLO_USDT">
                    Gate
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://app.soy.finance/swap">
                    Soy Finance DEX
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="https://www.youtube.com/watch?v=ScF5EXKT-f0">
                  Cold Staking Guide
                </Nav.Link>
                */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="hero">
        <div className="container text-center">
          <div className="heroTitle">The Callistonians: A Crypto Christmas Celebration</div>
          <div className="heroSlogon">
          <span>Welcome, Callistonians! We're honored to collaborate again with Krudo, whose artistry resonates with Callisto Network ethos.</span>
          <span>His unique blend of mysticism, rebellion, and satire perfectly encapsulates the essence of Callisto Network.</span>
          <span>Owners of the 253 Callistonian NFTs, get ready for a spectacular Christmas surprise!</span>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="container text-center">
          <div className="boxImagecontainer">
            <div className="boxImage">
              {!nftExists ? (
                <img src={nftBox_img} alt="nft box" className="img-fluid" />
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {!btnTxn ? (
            <div className="mintBtn">
              {walletConnected ? (
                !nftExists ? (
                  <Button
                    variant="primary"
                    size="lg"
                    active
                    onClick={onClickMint}
                  >
                    Mint Your NFT
                  </Button>
                ) : (
                  btnTxn ? (
                    <div className="loadingGif text-center">
                      <img src={loadingGif} alt="Loading" />
                    </div>
                  ) : (
                  <div className="nfts_content">
                    {nfts.map((nft, index) => (
                      <div key={index} className="nft_container">
                        <div className="nft_preview">
                          <div className="nft_image">
                            {nft.file_type === "mp4" ? (
                              <video
                                autoPlay
                                muted
                                loop
                                id="main"
                                width="100px"
                              >
                                <source
                                  src={nft.image_preview}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <img src={nft.image_preview} alt="" />
                            )}
                          </div>
                        </div>
                        <p>{nft.name}</p>
                        <div className="nft_tokenNb">
                          The Callistonians - #{nft.token_id}
                        </div>
                      </div>
                    ))}
                  </div>
                  )
                )
              ) : (
                <Button
                  variant="primary"
                  size="lg"
                  active
                  onClick={onClickConnect}
                >
                  Connect Wallet
                </Button>
              )}
            </div>
          ) : (
            <div className="loadingGif text-center">
              <img src={loadingGif} alt="Loading" />
            </div>
          )}

          <div className="boxDescription">
            Hold proudly your Callistonians NFTs, and get ready to welcome these new masterpieces into your collection!
          </div>
        </div>
      </div>
      <div className="parag">
        <div className="container">
          <div className="paragTitle">Eligibility</div>
          <div className="paragText">
            <p className="parag-eligible">
            To be eligible, it is necessary to be a Callistonians NFT holder as of 12/20/23 at 12:00 UTC (noon). A snapshot will be taken at this time.
            <span className="p-eligible">
            The eligible Callistonians NFT holders will be allowed to mint their Callistonians NFT between 12/24/23 and 01/07/24.
            </span>
            <span className="p-eligible">
            You can read more about The Callistonians NFTs series <a href="https://callisto.network/callistonians-nfts/">here</a>.
            </span>
            </p>
            <p>
              <span className="boldSpan common">
              •  Common NFT Owners:
              </span>{" "}
              Be gifted a new Common Callistonian NFT.
            </p>
            <p>
              <span className="boldSpan rare">
              • Rare NFT Owners: 
              </span>{" "}
              Receive a Rare Callistonian NFT.
            </p>
            <p>
              <span className="boldSpan super-rare">
              • Super Rare NFT Owners:
              </span>{" "}
              Obtain a Super Rare Callistonian NFT.
            </p>
          </div>
        </div>
      </div>
      <div className="allkeys">
        <div className="container">
          <div className="allkeysTitle text-center">
          CallistoNFT Standard: A New Era in Digital Ownership
          </div>
          <div className="keys">
            <div className="key">
              <div className="keyVideo">
                <ReactPlayer
                  playing={true}
                  loop
                  width={"400px"}
                  muted
                  url={[{ src: "1_VP9.webm", type: "video/webm" }]}
                />
              </div>
              <div className="keyTitle">
                Built-in Trades
              </div>
              <div className="keyText">
               CallistoNFT standard enables direct transactions within the asset, allowing buyers to bid directly on your NFT.
              </div>
            </div>
            <div className="key key2">
              <div className="keyVideo">
                  <ReactPlayer
                    playing={true}
                    loop
                    width={"400px"}
                    muted
                    url={[{ src: "2_VP9.webm", type: "video/webm" }]}
                  />
              </div>
              <div className="keyTitle">Built-in Monetization</div>
              <div className="keyText">
                In an era where royalties are crucial, CallistoNFT allows creators to set and adjust royalty structures automatically.
              </div>
            </div>
            <div className="key key3">
              <div className="keyVideo">
                <ReactPlayer
                  playing={true}
                  loop
                  width={"400px"}
                  muted
                  url={[{ src: "3_VP9.webm", type: "video/webm" }]}
                />
              </div>
              <div className="keyTitle">Innovative Communication Model</div>
              <div className="keyText">
                CallistoNFT ensures secure transactions, preventing them from being sent to unintended addresses.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer text-center">© 2023 Callisto Enterprise, Inc.</div>
    </div>
  );
}

export default App;
